import React from "react";
import Card from "./Card";

const CardContainer = ({ confirmed, deaths, recovered, loading }) => {
  return (
    <div className="highlights">
      <Card
        count={confirmed}
        category="Confirmed"
        bgcolor="#008B8B"
        loading={loading}
      />
      <Card
        count={deaths}
        category="Deaths"
        bgcolor="#FF0000"
        loading={loading}
      />
      <Card
        count={recovered}
        category="Recovered"
        bgcolor="#3CB371"
        loading={loading}
      />
    </div>
  );
};

export default CardContainer;
