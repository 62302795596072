import React from "react";
import AnimatedNumber from "react-animated-number";
import { formatNumber } from "../utils/display-utils";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Card = ({ category, count, bgcolor, loading }) => {
  return (
    <section>
      <div className="content">
        <header>
          {loading ? (
            <div>
              <Loader type="Hearts" color="#ffffff" height={60} width={60} />
            </div>
          ) : (
            <h2 style={{ fontWeight: 500 }}>
              <AnimatedNumber
                component="span"
                value={count}
                style={{
                  transition: "0.8s ease-out",
                  transitionProperty: "background-color, color, opacity",
                  color: bgcolor
                }}
                duration={500}
                stepPrecision={0}
                formatValue={n => formatNumber(n)}
              />
            </h2>
          )}
        </header>
        <p>{category}</p>
      </div>
    </section>
  );
};

export default Card;
