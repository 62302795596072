import React, { useEffect, useState } from "react";
import Global from "./Global";
import State from "./State";
import Footer from "./Footer";
import { fetchWorldData } from "../services/data";

const App = () => {
  const [world, setWorld] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const runAsync = async () => {
      const result = await fetchWorldData();
      setWorld(result);
      setLoading(false);
    };
    runAsync();
  }, []);

  return (
    <section className="wrapper">
      <Global loading={loading} world={world} />
      <State />
      <Footer />
    </section>
  );
};

export default App;
