import axios from "axios";

const fetchCountryLocation = () => {
  const country = axios
    .get(`https://ipapi.co/json/`)
    .then(response => {
      if (response && response.data) {
        return response.data.country_code;
      }
    })
    .catch(err => {
      console.error(err);
      return "PH";
    });

  return country;
};

export default fetchCountryLocation;
