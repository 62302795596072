import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import CardContainer from "./CardContainer";
import fetchCountryLocation from "../services/country";
import { fetchLocalData } from "../services/data";
import LastUpdated from "./LastUpdated";

const State = () => {
  const [local, setLocal] = useState({});
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState();

  useEffect(() => {
    const runAsync = async () => {
      const location = await fetchCountryLocation();
      setCountry(location);
    };
    runAsync();
  }, []);

  useEffect(() => {
    setLocal({});
    setLoading(true);
    if (country) {
      const runAsync = async () => {
        try {
          const local = await fetchLocalData(country);

          if (!local) {
            setLocal({
              confirmed: { value: 0 },
              deaths: { value: 0 },
              recovered: { value: 0 },
              lastUpdate: null,
            });
          } else {
            setLocal(local);
          }
        } catch (err) {
          console.error(err);
          setLocal({
            confirmed: { value: 0 },
            deaths: { value: 0 },
            recovered: { value: 0 },
            lastUpdate: null,
          });
        } finally {
          setLoading(false);
        }
      };
      runAsync();
    }
  }, [country]);

  return (
    <div className="inner">
      <header className="special">
        <ReactFlagsSelect
          searchable={true}
          defaultCountry="CA"
          searchPlaceholder="Search for a country"
          onSelect={setCountry}
          className="menu-flags"
        />
        {local && local.lastUpdate && (
          <div style={{ marginTop: "20px" }}>
            <LastUpdated date={local.lastUpdate} />
          </div>
        )}
      </header>
      <CardContainer
        loading={loading}
        confirmed={local.confirmed && local.confirmed.value}
        deaths={local.deaths && local.deaths.value}
        recovered={local.recovered && local.recovered.value}
      />
    </div>
  );
};

export default State;
