import React from "react";
import Moment from "react-moment";

const LastUpdated = ({ date }) => {
  return (
    <span className="label">
      Last updated:{" "}
      <Moment className="yellow" format="llll">
        {date}
      </Moment>
    </span>
  );
};

export default LastUpdated;
