import React from "react";
import CardContainer from "./CardContainer";
import LastUpdated from "./LastUpdated";

const Global = ({ world, loading }) => {
  return (
    <div className="inner">
      <header className="special">
        <p style={{ fontSize: "36px" }}>COVID-19 TRACKER</p>
        <p>Brought To You By The Travel Boss</p>
        <h4>
          <img
            width="100"
            height="100"
            alt="The Travel Boss COVID-19"
            src="https://res.cloudinary.com/diubhhsbw/image/upload/v1584975649/ttbmask_copy.png"
          />
        </h4>
        <div>
          <h3>World Stats</h3>
          <LastUpdated date={world.lastUpdate} />
        </div>
      </header>
      <CardContainer
        loading={loading}
        confirmed={world.confirmed && world.confirmed.value}
        deaths={world.deaths && world.deaths.value}
        recovered={world.recovered && world.recovered.value}
      />
    </div>
  );
};

export default Global;
