import api from "./api";

export const fetchLocalData = country => {
  const data = api
    .get(`/api/countries/${country}`)
    .then(response => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch(err => {
      console.error(err);
    });

  return data;
};

export const fetchWorldData = () => {
  const data = api
    .get("/api")
    .then(response => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch(err => {
      console.error(err);
    });
  return data;
};
