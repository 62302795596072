import React from "react";

const Footer = () => {
  return (
    <div className="footer" style={{ textAlign: "center", color: "white" }}>
      API provided by{" "}
      <a
        href="https://github.com/mathdroid/covid-19-api"
        target="_blank"
        rel="noopener noreferrer"
        className="yellow"
      >
        Mathdroid
      </a>
      .
    </div>
  );
};

export default Footer;
